<template>
  <div id="tougao">
    <div class="back"></div>
    <div class="content">
      <div class="con_top clearfix">
        <div class="pull-left">
          <h3>双碳平台投稿征集</h3>
          <p><icon-check-circle-fill />投稿标准研制全生命周期内容，为您研制的标准增加传播渠道，提升研制效率</p>
          <p><icon-check-circle-fill />展现双碳领域典型案例，助力品牌曝光，加速商业变现</p>
          <div class="button">
            <a-button type="primary" @click="visible = true"><svg-icon icon-class="headset_mic"></svg-icon>联系投稿客服</a-button>
            <a-button type="outline" @click="eventClick">在线投稿文章</a-button>
          </div>
        </div>
        <div class="pull-right">
          <img src="../assets/img/tougao.png" />
        </div>
      </div>
      <div class="con_center">
        <h3>服务流程</h3>
        <div class="clearfix">
          <div class="pull-left">
            <img src="../assets/img/steps.png" />
          </div>
          <div class="pull-left">
            <div class="item" v-for="(item, index) in itemList" :key="index">
              <span>{{ index + 1 }}</span>
              <div>
                <h4>{{ item.title }}</h4>
                <p>{{ item.tip }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="con_btn">
        <a-button type="primary"  @click="visible = true"><svg-icon icon-class="headset_mic"></svg-icon>联系投稿客服</a-button>
      </div>
    </div>
    <TougaoPanel :visible="visible" @close="visible = false"></TougaoPanel>
  </div> 
</template>
<script>
import TougaoPanel from '../components/TougaoPanel.vue'
export default {
  components: {
    TougaoPanel
  },
  data() {
    return {
      itemList: [
        { 
          title: '联系投稿客服',
          tip: '可通过联系投稿客服发送文章，或直接点击上方「在线投稿文章」按钮提交信息。'
        },
        { 
          title: '投稿文章',
          tip: '成功提交文章后，客服会在 1 个工作日内主动与您取得联系，进入协议流程。'
        },
        { 
          title: '发布文章',
          tip: '协议流程完成后，可立即或按要求时间发布文章。'
        },
      ],
      visible: false
    }
  },

  mounted() {
    this.$nextTick(() => {
      document.getElementsByClassName('layout_container')[0].style.paddingTop = '94px'
    })
  },
  beforeDestroy() {
    document.getElementsByClassName('layout_container')[0].style.paddingTop = '134px'
  },
  methods: {
    eventClick() {
      window.open('https://tgik6tfkz3.jiandaoyun.com/f/61cd76ab0b1b470007b0d3e7')
    }
  }
}
</script>
<style lang='scss'>
  #tougao {
    position: relative;
    margin-bottom: 20px;

    .back {
      position:absolute;
      top: 0;
      left: 0;
      height: 300px;
      background: linear-gradient(180deg,#e5fffa, rgba(255,255,255,0.00));
      width: 100%;
    }

    .content {
      width: 1200px;
      margin: 0 auto;
      z-index: 10px;
      position: relative;

      .con_top {
        padding: 80px 100px 0 60px;
        margin-bottom: 50px;

        h3 {
          font-size: 32px;
          color: #3f3d56;
          line-height: 45px;
          padding: 20px 0 30px;
        }

        p {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #1d2129;
          line-height: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .arco-icon {
            margin-right: 12px;
            color: #05a081;
            width: 20px;
            height: 20px;
          }

          
        }

        .button {
          margin-top: 40px;
          display: flex;
          align-items: center;

          .arco-btn {
            width: 200px;
            height: 50px;
            border-radius: 4px;
            margin-right: 20px;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;

            .svg-icon {
              width: 20px;
              height: 20px;
              margin-right: 10px;
              color: #fff;

            }
          }
        }

        img {
          width: 380px;
          height: 315px;
        }
      }

      .con_center {
        background: #fafafa;
        border-radius: 10px;
        padding: 40px 60px 70px;
        margin-bottom: 50px;

        h3 {
          line-height: 45px;
          font-size: 32px;
          text-align: center;
          color: #3f3d56;
          margin-bottom: 30px;
        }

        img {
          width: 280px;
          height: 250px;
          margin-right: 110px;
        }

        .pull-left {
          .item {
            display: flex;
            margin-bottom: 36px;

            & >span {
              display: block;
              width: 24px;
              height: 24px;
              background: #05a081;
              border-radius: 50%;
              line-height: 24px;
              text-align: center;
              color: #fff;
              margin-right: 20px;
            }

            & > div {
              h4 {
                font-size: 16px;
                color: #1d2129;
                line-height: 22px;
                margin-bottom: 5px;
              }

              p {
                font-size: 14px;
                color: #4e5969;
                line-height: 24px;
              }
            }
          }
        }
      }

      .con_btn {
        padding: 0 20px;
        text-align: center;

        .arco-btn {
          height: 60px;
          border-radius: 30px;
          box-shadow: 0px 5px 10px 0px rgba(5,160,129,0.20); 
          width: 200px;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;

          .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            color: #fff;
          }
        }
      }

    }
  }
</style>
