<template>
  <a-modal 
    :visible="visible" 
    :unmount-on-close="true"
    :footer="false"
    modal-class="relation_modal"
    title=""
    width="400"
    @cancel="$emit('close')">
    <template #title> </template>
    <div class="panel">
      <img style="width: 36px;height: 36px;" src="../assets/img/wechat.png" />
      <div>
        <h4>联系投稿微信</h4>
        <p>微信扫右侧二维码，</p>
        <p>或添加微信号：chowchihang</p>
      </div>
      <img style="width: 80px;height: 80px;" src="../assets/img/tougao_qrcode.png" />
    </div>
    <a-divider />
    <div class="panel" style="padding: 20px 10px;">
      <img style="width: 36px;height: 36px;" src="../assets/img/qq.png" />
      <div>
        <h4>联系投稿QQ</h4>
        <p>QQ号：1104865327</p>
      </div>
      <a-button type="outline" @click="eventClick">立即联系</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: ['visible', 'detail'],
  data() {
    return { 
    }
  },
  methods: {
    eventClick() {
      window.location = 'http://wpa.qq.com/msgrd?v=3&uin=1104865327&site=qq&menu=yes'
    }
  }
  
}
</script>

<style lang='scss'>
.relation_modal {
  text-align: left;

  .arco-modal-header {
    height: 48px;
  }

  .arco-modal-body {
    padding:  0 16px 20px;

    .panel {
      padding: 5px 10px;
      display: flex;
      align-items: center;

      div {
        margin: 0 13px 0 15px;
        width: 204px;

        & > h4 {
          font-size: 16px;
          color: #1d2129;
          line-height: 22px;
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
          color: #86909c;
          line-height: 18px;
        }
      }

      .arco-btn {
        height: 36px;
        background: #ffffff;
        border: 1px solid #05a081;
        border-radius: 4px;
        width: 80px;
        text-align: center;
        padding: 0;
      }
    }

    .arco-divider-horizontal {
      margin:10px 0;
    }
  }
}


</style>